.overlay {
	width: calc(100vw);
	height: calc(100vh);
	background: rgba(0, 0, 0, 0.4);
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
}

.root {
	width: 90%;
	max-width: 90%;
	height: 90%;
	max-height: 90%;
	top: calc(5vh);
	left: calc(5vh);
	background: #fff;
	border-radius: 8px;
	padding: 16px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px;
}

.message {
	font-size: 20px;
	text-align: left;
	padding: 20;
}

/* Tablet */
@media screen and (max-width: 768px) {
	.message {
		min-width: 200px;
	}
}

/* Mobile L */
@media screen and (max-width: 425px) {
	.header {
		flex-direction: column;
		height: 230px;
	}
}
