.copy-service-email {
	margin-right: 12px;
}
.copy-service-email:focus {
	background: #ccc;
}

.root {
	display: flex;
	flex-direction: column;
	padding: 16px;
}

.subheader {
	font-size: 36px;
	margin: 36px 0 12px;
	text-align: left;
	font-weight: 300;
}

.sites-aliases-container {
	display: flex;
	margin: 8px 0;
	font-size: 18px;
	display: flex;
}
.sites-aliases-container div:first-child {
	width: 250px;
	text-align: left;
}
.sites-aliases-container span {
	margin: 0 12px;
}

.feedback-container {
	text-align: left;
	display: flex;
	align-items: center;
	margin: 8px 0;
	padding: 8px;
	border-bottom: 1px solid #333;
}

/* Mobile L */
@media screen and (max-width: 425px) {
	.sites-aliases-container {
		flex-direction: column;
		align-items: flex-start;
		text-align: left;
		margin: 16px 0;
	}
	.sites-aliases-container span {
		margin: 0;
	}
}
