.root {
	display: flex;
	width: 100%;
	background: #fff;
	border: 1px solid #ccc;
	border-width: 1px 0px 0px;
	cursor: pointer;
	box-sizing: border-box;
	padding: 16px;
	justify-content: space-between;
}
.root:hover {
	background: #eee;
}
.root:active {
	background: #ddd;
}
