.root {
	position: relative;
	display: flex;
}

.tab {
	text-align: left;
	font-size: 32px;
	font-weight: 300;
	margin: 0;
	box-sizing: border-box;
	width: 210px;
	text-align: center;
	background: transparent;
	border: none;
	outline: none;
	cursor: pointer;
	padding: 4px;
	box-sizing: border-box;
}
.tab:hover {
	color: var(--primary-main);
}

.underline {
	height: 3px;
	width: 210px;
	background: var(--primary-main);
	position: absolute;
	bottom: 0;
	transition: 300ms;
}

.underline-small {
	width: 120px;
}

.small {
	font-size: 16px;
	width: 120px;
}
