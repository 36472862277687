.root {
	margin: 8px;
	border-radius: 4px;
	width: calc(50% - 16px);
	background: #fff;
	padding: 12px;
	max-width: 100%;
	box-sizing: border-box;
	cursor: pointer;
	border: 2px solid transparent;
	transition: 300ms;
	display: flex;
	align-items: center;
	justify-content: space-between;
	/*border: 2px solid #000;*/
}
.root:hover {
	border: 2px solid var(--primary-light);
}

.header {
	margin: 0;
	text-transform: capitalize;
	text-align: left;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.counter {
	display: flex;
	align-items: center;
	width: 32px;
	justify-content: space-around;
	margin-left: 8px;
	font-size: 16px;
}

@media screen and (max-width: 1000px) {
	.root {
		width: calc(100% - 16px);
	}
}
