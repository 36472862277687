:root {
	/*--primary-main: #6fc276;*/

	--primary-light: #73cb8a;
	--primary-main: #20a84e;
	--primary-dark: #277e3e;
	--secondary-light: #e29681;
	--secondary-main: #d6694b;
	--secondary-dark: #964a35;

	--secondary: #333;

	--background-color: #f6f8fe;
	--button-color: var(--primary-main);

	--footer-height: 100px;
	--nav-height: 50px;
}

/* width */
::-webkit-scrollbar {
	width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	background: rgba(0, 0, 0, 0.15);
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.App {
	text-align: center;
	color: #333;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.error-text {
	text-align: left;
	color: #e57373;
}

.flex-center {
	display: flex;
	align-items: center;
}
