.root {
	position: absolute;
	left: -300px;
	display: flex;
	align-items: center;
	flex-direction: column;
	background: #fff;
	border-radius: 8px;
	width: 300px;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
	overflow: hidden;
	box-sizing: border-box;
	z-index: 1;
}

.email-row {
	padding: 24px 0;
}

.action-row {
	background: #fff;
	border: none;
	padding: 12px 16px;
	font-size: 18px;
	cursor: pointer;
	transition: background 300ms;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.action-row:hover {
	background: #eee;
}
.action-row:active {
	background: #bbb;
}

.divider {
	width: 100%;
	border-top: 1px solid #ccc;
}

.footer-row {
	display: flex;
	font-size: 12px;
	padding: 16px 0;
}
.footer-row a {
	text-decoration-line: none;
	color: #555;
	transition: color 200ms;
}
.footer-row a:hover {
	color: var(--primary-main);
}
.footer-row a:first-child {
	margin-right: 4px;
	padding-right: 4px;
	border-right: 1px solid #555;
}

.backdrop {
	position: absolute;
	width: calc(100vw);
	height: calc(100vh);
	right: -24px;
	top: -8px;
	z-index: 0;
	/*background: rgba(0, 0, 0, 0.05);*/
}

/* Mobile L */
@media screen and (max-width: 425px) {
	.root {
		width: calc(90vw);
		left: calc(5vw);
		right: calc(5vw);
	}
}
