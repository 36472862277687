.wrapper {
	display: inline-block;
}

.root {
	background: var(--button-color);
	cursor: pointer;
	min-width: 100px;
	padding: 8px;
	/*text-transform: uppercase;*/
	transition: 200ms;
	letter-spacing: 1px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	color: #fff;
	border-radius: 2px;
	border: none;
	box-shadow: 1px 1px 1px grey;
	font-family: inherit;
	font-weight: 600;
}
.root:active {
	box-shadow: none;
}
.root:disabled {
	background: #eee;
	color: #ccc;
	border-color: #ccc;
	box-shadow: none;
	cursor: default;
	pointer-events: none;
}

.icon {
	width: unset;
	min-width: 0;
	max-height: 40px;
	color: var(--button-color);
	fill: var(--button-color);
	stroke: var(--button-color);
	padding: 4px;
	font-size: 20px;
}

/* === LOADING === */
.loading {
	display: inline-block;
	width: 24px;
	height: 24px;
}
.loading:after {
	content: ' ';
	display: block;
	width: 14px;
	height: 14px;
	border-radius: 50%;
	border: 5px solid #aaa;
	border-color: #aaa transparent #aaa transparent;
	animation: button-loading 1.2s linear infinite;
}
@keyframes button-loading {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
