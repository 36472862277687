.root {
	position: fixed;
	bottom: 8px;
	right: 8px;
	margin-left: 8px;
	padding: 16px;
	border-radius: 8px;
	background: var(--background-color);
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
	max-width: 400px;
	text-align: left;
}

.header {
	margin: 0 0 8px;
	font-size: 24px;
	font-weight: 500;
}

.accept {
	margin-top: 12px;
}
