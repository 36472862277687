.root {
	background: var(--background-color);
	height: calc(100vh - var(--nav-height));
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.form-container {
	margin-top: 64px;
	width: 350px;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 24px;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
		rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
	background: #fff;
}

.form-header {
	font-size: 20px;
	margin-bottom: 16px;
}

.create-account {
	text-decoration: none;
	color: var(--primary-main);
	margin-top: 8px;
}

.email-container {
	display: flex;
	align-items: center;
	width: 100%;
	flex-direction: column;
	height: 90px;
	justify-content: space-between;
}

.email-input {
	width: 100%;
	padding: 8px;
	border: 1px solid #999;
	border-radius: 4px;
	font-size: 16px;
	box-sizing: border-box;
}
.email-input:focus {
	outline: 1px solid var(--primary-main);
}

.different-email {
	background-color: transparent;
	color: var(--primary-dark);
	border: none;
	outline: none;
	margin-top: 24px;
	cursor: pointer;
}

/* Mobile M */
@media screen and (max-width: 375px) {
	.form-container {
		width: 90%;
		padding: 8px;
		box-sizing: border-box;
	}
}
