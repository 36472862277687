.root {
	/*height: calc(100vh - var(--footer-height) - var(--nav-height));*/
	overflow: auto;
	box-sizing: border-box;
	background-color: var(--background-color);
}

.header {
	font-family: inherit;
}

.body {
	font-family: inherit;
	font-size: 18px;
	margin-bottom: 36px;
	padding: 64px 96px;
	text-align: left;
}

/* Mobile L */
@media screen and (max-width: 425px) {
	.body {
		padding: 12px;
	}
}
