.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	min-height: 500px;
	background: rgba(0, 0, 0, 0.3);
	display: flex;
	align-items: center;
	justify-content: center;
}

.close {
	position: absolute;
	top: 15px;
	right: 10px;
	background: #fff;
	border: none;
	color: #ccc;
	cursor: pointer;
}
.close:hover {
	color: var(--secondary-main);
}

.root {
	position: relative;
	background: #fff;
	min-width: 300px;
	width: 500px;
	border-radius: 8px;
	padding: 32px;
	box-sizing: border-box;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
		rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
		rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.header {
	font-size: 32px;
	color: #333;
}

.text {
	color: #333;
	font-size: 16px;
}

.email-container {
	display: flex;
	justify-content: center;
	margin: 16px 0px;
}

.email {
	border-radius: 4px;
	padding: 8px;
	background: #000;
	color: #fff;
	font-weight: 500;
}

.spacer {
	height: 48px;
}

/* Mobile L */
@media screen and (max-width: 425px) {
	.header {
		font-size: 22px;
		font-weight: 600;
	}

	.root {
		margin-top: var(--nav-height);
		max-width: 90%;
		max-height: calc(95% - var(--nav-height));
		padding: 32px 12px;
	}

	.email {
		font-size: 12px;
		align-items: center;
		display: flex;
	}

	.spacer {
		height: 24px;
	}
}
