.root {
	background: var(--button-color);
	border: none;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	transition: 200ms;
	font-size: 16px;
	width: 35px;
	height: 35px;
	cursor: pointer;
	box-shadow: 1px 1px 1px grey;
	padding: 0;
}
.root:active {
	box-shadow: none;
}

.root:disabled {
	background: #eee;
	color: #ccc;
	box-shadow: none;
	cursor: default;
	pointer-events: none;
}
