.root {
	/*max-height: calc(100vh - 500px);*/
	background: #f0f3fa;
	padding: 34px 0 0;
	align-items: center;
	display: flex;
	flex-direction: column;
	min-height: calc(100vh - var(--nav-height));
	box-sizing: border-box;
}

.inner-root {
	width: 100%;
	padding: 0 32px;
	box-sizing: border-box;
	max-width: 1200px;
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.email {
	border-radius: 4px;
	padding: 8px;
	background: #000;
	color: #fff;
	font-weight: 600;
}

.copy-email {
	margin-left: 8px;
	color: #333;
	background: var(--secondary-light);
	border-radius: 4px;
	border: none;
	font-size: 18px;
	transition: 300ms;
}
.copy-email:hover {
	cursor: pointer;
	background: var(--secondary-main);
}
.copy-email:active {
	cursor: pointer;
	background: var(--secondary-light);
	box-sizing: none;
}

.content {
	padding: 32px 0;
	min-height: 500px;
}

.no-user-sites {
	font-size: 24px;
	font-weight: 300;
	margin: 24px 0 12px;
}

.button {
	margin: 0 4px;
}

.search {
	width: 350px;
	box-sizing: border-box;
}

.search input {
	font-size: 18px;
	width: 100%;
	background: transparent;
	border: none;
	border-bottom: 2px solid var(--secondary);
	margin-right: 8px;
	transition: border 300ms;
	border-radius: 0px;
}

.search input:focus {
	outline: none;
	border-bottom: 2px solid var(--primary-main);
}

.recent-coupons-container {
	margin-bottom: 32px;
}

.learn-to-use {
	width: 400;
	margin: auto;
	margin-top: 16px;
}

.tab-subtext {
	text-align: left;
	max-width: 530px;
	padding: 0 0 8px;
}

.feedback-fab {
	position: fixed;
	bottom: 12px;
	right: 12px;
	z-index: 100;
	width: 55px;
	height: 55px;
	font-size: 32px;
}

/* Tablet */
@media only screen and (max-width: 768px) {
	.header {
		margin-bottom: 8px;
	}

	.your-email {
		font-size: 16px;
	}
}

/* Mobile L */
@media screen and (max-width: 425px) {
	.header {
		flex-direction: column;
		height: 90px;
	}
}

/* Mobile M */
@media screen and (max-width: 375px) {
	.inner-root {
		padding: 4px 16px;
	}

	.search {
		width: 100%;
	}
}
