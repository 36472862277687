.root {
	display: flex;
	width: 100%;
}

.site-cards-container {
	flex-wrap: wrap;
	display: flex;
	width: 100%;
	flex-grow: 2;
	height: 100%;
}

.focus-container {
	flex-grow: 3;
	border: 2px solid #000;
	border-radius: 4px;
	margin: 8px;
	text-align: center;
	height: 400px;
	width: 100%;
}

.focus-header {
	background: #fff;
	margin: 0;
	box-shadow: 0px 2px 4px #ccc;
	z-index: 1;
	height: 70px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.focus-inner-container {
	width: 100%;
	max-width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	background-color: #fff;
}

.focus-list {
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: auto;
	width: 100%;
	height: 330px;
}

.selected-site-header {
	text-transform: capitalize;
	margin: 8px;
}

.focus-zero-state {
	margin: auto;
	max-width: 60%;
	justify-content: center;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.zero-state-root {
	display: flex;
	align-items: center;
	flex-direction: column;
}

/* Tablet */
@media screen and (max-width: 768px) {
	.root {
		flex-direction: column;
	}
	.focus-container {
		margin: 0;
	}
}
