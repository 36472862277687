.root {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 4px 8px;
	border: solid #ccc;
	border-width: 0 0px 1px;
	height: 50px;
	transition: 200ms;
	width: inherit;
	box-sizing: border-box;
	background: #fff;
	cursor: pointer;
}
.root:hover {
	background: #eee;
}
.root:active {
	background: #ddd;
}

.row span {
	font-size: 14px;
}

.subject {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding-right: 8px;
	text-align: left;
	max-width: 280px;
}
