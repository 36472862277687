.root {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	color: #999;
	width: 100%;
	/*height: var(--footer-height);*/
	background: #121111;
	padding: 32px 24px;
	bottom: 0;
	position: relative;
	box-sizing: border-box;
	box-sizing: border-box;
}

.top-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.link {
	color: #999;
	margin: 8px;
	cursor: pointer;
	transition: 200ms;
	text-decoration-line: none;
}
.link:hover {
	color: var(--secondary-main);
}

.link-divider {
	width: 1px;
	background: #999;
	height: 18px;
	margin: 0 4px;
}

.copyright {
	margin: 8px;
	display: flex;
	align-items: center;
}

/* Mobile L */
@media screen and (max-width: 425px) {
	.copyright {
		flex-direction: column;
		margin-top: 24px;
	}
}
