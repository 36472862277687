.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: calc(100vw);
	height: calc(100vh);
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal-root {
	background: #fff;
	padding: 16px;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 85%;
}

.header {
	font-family: inherit;
	font-size: 20px;
}

.textarea {
	resize: none;
	margin: 16px 0;
	font-family: inherit;
	padding: 4px;
	border-radius: 4px;
	max-width: 100%;
}
