.root {
	width: 100%;
	color: #000;
	box-sizing: border-box;
	padding: 8px 24px;
	display: flex;
	justify-content: space-between;
	background: var(--primary-main);
	align-items: center;
	height: var(--nav-height);
	top: 0px;
	box-shadow: 4px 0px 10px rgba(223, 225, 230, 0.4);
	position: sticky;
	z-index: 2;
}

.title {
	margin-left: 8px;
	font-size: 28px;
	font-weight: 600;
}

.title-button {
	all: unset;
	cursor: pointer;
	display: flex;
	align-items: center;
}

.profile-menu-container {
	position: relative;
}

.join-up {
	text-decoration-line: none;
	padding: 8px 16px;
	border-radius: 24px;
	background: var(--primary-main);
	color: #fff;
	font-weight: 600;
	transition: 300ms;
}
.join-up:hover {
	background: var(--primary-dark);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.join-up:active {
	box-shadow: none;
	background-color: var(--primary-main);
}

.login {
	margin-right: 16px;
	text-decoration-line: none;
	color: #000;
}

.header {
	color: #fff;
	font-size: 28px;
	font-weight: 500;
	margin: 0;
	text-decoration: none;
}

.link {
	color: #fff;
	font-weight: 600;
	font-size: 16px;
	text-decoration: none;
	transition: 200ms;
	margin: 0 8px;
}
.link:hover {
	color: var(--secondary-main);
}

.divider {
	color: #fff;
}

/* Mobile L */
@media screen and (max-width: 425px) {
	.profile-menu-container {
		position: unset;
	}
}

/* Mobile S */
@media screen and (max-width: 325px) {
	.root {
		padding: 8px 12px;
	}

	.title {
		font-size: 24px;
	}

	.join-up {
		padding: 8px 12px;
	}
}
