.header {
	background-image: url('./assets/top-background.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	display: flex;
	align-items: center;
	flex-direction: column;
	height: 650px;
	min-height: 500px;
	width: 100%;
}

.header-inner-container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.header-image {
	height: 350px;
	margin: 16px 32px;
	width: auto;
}

.header-left {
	width: 40%;
	max-width: 550px;
	box-sizing: border-box;
}

.header-title {
	font-size: 3em;
	text-align: left;
	margin-top: 64px;
	/*margin-bottom: 64px;*/
	margin-bottom: 32px;
	letter-spacing: -1px;
}

.header-title-name {
	font-size: inherit;
	color: var(--primary-main);
}

.header-email-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.header-email-input {
	flex-grow: 1;
	font-size: 18px;
	padding: 12px 0 12px 12px;
	border-radius: 24px;
	box-shadow: none;
	border: 2px solid #000;
	margin-right: 8px;
	transition: border 200ms;
}
.header-email-input:focus {
	outline: none;
	border: 2px solid var(--primary-main);
}

.header-button {
	background: var(--primary-main);
	color: #fff;
	border: none;
	outline: none;
	border-radius: 24px;
	padding: 14px;
	font-size: 18px;
	cursor: pointer;
	font-weight: 600;
	transition: 200ms;
}
.header-button:hover {
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
	background: var(--primary-dark);
}
.header-button:active {
	box-shadow: none;
	background: var(--primary-main);
}

.get-started {
	font-size: 32px;
	border: none;
	cursor: pointer;
	text-decoration-line: none;
	padding: 8px 16px;
	border-radius: 8px;
	background: var(--primary-main);
	color: #fff;
	transition: 300ms;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
	font-family: inherit;
}
.get-started:hover {
	background: var(--primary-dark);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.get-started:active {
	box-shadow: none;
	background-color: var(--primary-main);
}

.subheader {
	width: 100%;
}

.subheader-text {
	font-size: 36px;
	font-weight: 600;
}

.subheader-image-container {
	background: url('./assets/subheader-background.svg');
	background-repeat: no-repeat;
	background-position: top;
	background-size: 45%;
	width: 100%;
	padding: 0px 0 128px;
	margin-top: 16px;
}

.subheader img {
	width: 60%;
	height: auto;
}

.counter-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.counter-number {
	margin: 4px;
	padding: 6px 8px;
	font-size: 36px;
	background: #333;
	color: #fff;
	border-radius: 8px;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.6);
	border: 2px solid #fff;
	position: relative;
}
/*.counter-number:before {
	content: ' ';
	width: 100%;
	height: 1px;
	background: #fff;
	border-top: 1px solid #000;
	border-bottom: 1px solid #000;
	position: absolute;
	left: 0;
	top: 50%;
	bottom: 50%;
}*/

.plus {
	font-size: 32px;
}

.found-so-far {
	font-size: 32px;
	margin: 16px 0 64px;
}

.hiw-container {
	background-image: url('./assets/hiw-background.svg');
	background-repeat: no-repeat;
	background-position: bottom;
	background-size: cover;
	/*background-color: var(--background-color);*/
	padding: 24px;
	display: flex;
	align-items: center;
	flex-direction: column;
	box-sizing: border-box;
}

.hiw-title {
	font-size: 42px;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	margin-bottom: 24px;
}
.hiw-title svg:first-child {
	margin-left: 16px;
}

.hiw-subtitle {
	width: 500px;
	line-height: 1.5;
	margin-bottom: 42px;
}

.fold-row {
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 100%;
	box-sizing: border-box;
	max-width: 1200px;
}
.fold-row img {
	max-height: 400px;
}

.fold-row-column {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.fold-step {
	background: var(--primary-main);
	border-radius: 24px;
	font-size: 22px;
	font-weight: 600;
	color: #fff;
	padding: 4px 16px;
	display: flex;
	align-items: center;
	flex-direction: row;
	margin-bottom: 8px;
}

.fold-step span {
	background-color: #fff;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 8px;
}

.fold-row-text {
	width: 400px;
	font-weight: 400;
	text-align: left;
	font-size: 2em;
}

.fold-column {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 128px;
	margin-bottom: 128px;
}
.fold-column .fold-row-text {
	text-align: center;
	margin: 8px 0 32px;
}

.fold-subtext {
	margin: 12px 0;
	color: #666;
	font-size: 14px;
	width: 350px;
	text-align: left;
}

.bottom-photo {
	height: auto;
	max-height: 650px;
	width: auto;
	max-width: 100%;
}

/* Tablet */
@media screen and (max-width: 768px) {
	.header-image {
		/*display: none;*/
		height: 300px;
	}

	.fold-image {
		height: auto;
		width: auto;
		max-width: 50%;
	}

	.fold-row-text {
		max-width: 100%;
	}

	.fold-row-column {
		max-width: 50%;
		margin-left: 10px;
	}
}

/* Mobile L */
@media screen and (max-width: 425px) {
	.header-image {
		display: none;
	}

	.header-left {
		width: 100%;
		padding: 8px;
		box-sizing: border-box;
	}

	.header {
		height: unset;
	}

	.hiw-subtitle {
		max-width: 100%;
	}

	.fold-row {
		flex-direction: column;
		align-items: center;
	}

	.fold-row-column {
		align-items: center;
		margin-bottom: 64px;
		min-width: 100%;
	}

	.fold-image {
		display: none;
	}

	.fold-row-text {
		text-align: center;
		width: 100%;
	}

	.fold-subtext {
		width: 300px;
		text-align: center;
		box-sizing: border-box;
	}

	.fold-column {
		margin-top: 0;
	}
}

/* Mobile M */
@media screen and (max-width: 376px) {
	.header-title {
		text-align: center;
		line-height: 1.2;
		font-size: 2em;
	}
}
