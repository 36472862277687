.root {
	display: flex;
	align-items: center;
	width: 100%;
	margin-top: 32px;
}

.step-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 90px;
}

.step-number {
	margin: 4px;
	background: var(--primary-main);
	border: 2px solid var(--primary-main);
	border-radius: 50%;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-weight: 600;
	color: #fff;
}
.step-number.not-yet {
	background: #fff;
	border: 2px solid #aaa;
	color: #aaa;
}
.step-number.completed {
	background: var(--primary-main);
	color: #fff;
}

.step-text {
	font-size: 12px;
	font-weight: 500;
	color: #333;
}
.step-text.not-yet {
	color: #aaa;
}

.step-connector {
	height: 3px;
	/*width: 50px;*/
	flex-grow: 1;
	background: var(--primary-main);
	margin: 0 -30px 15px;
}
.step-connector.not-yet {
	background: #aaa;
}
