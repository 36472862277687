.root {
	display: flex;
	width: 100%;
}

.site-cards-container {
	flex-wrap: wrap;
	display: flex;
	width: 100%;
	flex: 3;
	height: 100%;
}

.focus-container {
	flex: 2;
	border: 2px solid #000;
	border-radius: 4px;
	margin: 8px;
	text-align: center;
	display: flex;
	align-items: center;
	height: 400px;
	box-sizing: border-box;
}

.focus-inner-container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.focus-discounts-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: auto;
	width: 100%;
	height: 350px;
}

.selected-site-header {
	text-transform: capitalize;
	background: #fff;
	margin: 0;
	padding: 16px;
	box-shadow: 0px 2px 4px #ccc;
	z-index: 1;
	height: 50px;
	box-sizing: border-box;
}

.zero-state {
	margin: auto;
	max-width: 60%;
}

/* Tablet */
@media screen and (max-width: 768px) {
	.root {
		flex-direction: column;
	}
}
