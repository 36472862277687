.root {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	height: calc(100vh - var(--nav-height));
	background: var(--background-color);
}

.form-container {
	margin-top: 64px;
	width: 400px;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 24px;
	/*box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
		rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;*/
	box-shadow: 4px 8px 25px rgba(2, 2, 70, 0.15);
	background: #fff;
}

.header {
	font-size: 22px;
	margin-bottom: 32px;
}

.username-input {
	width: 100%;
	padding: 8px;
	border: 1px solid #999;
	border-radius: 4px;
	font-size: 18px;
	box-sizing: border-box;
}
.username-input:focus {
	outline: 1px solid var(--primary-main);
}

.username-help-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	min-height: 40px;
	width: 100%;
}

.email-container {
	display: flex;
	align-items: center;
	width: 100%;
	flex-direction: column;
	height: 90px;
	justify-content: space-between;
}

.email-input {
	width: 100%;
	padding: 8px;
	border: 1px solid #999;
	border-radius: 4px;
	font-size: 16px;
	box-sizing: border-box;
}
.email-input:focus {
	outline: 1px solid var(--primary-main);
}

.your-email {
	text-align: left;
	font-size: 12px;
	color: #777;
	padding: 2px 0;
}

.create-account {
	margin: 12px auto 0;
}

.get-started {
	font-size: 22px;
	margin-bottom: 26px;
}

.agreements-link {
	text-decoration-line: none;
	color: var(--primary-dark);
}
.agreements-link:hover {
	text-decoration-line: underline;
}

.different-email {
	background-color: transparent;
	color: var(--primary-dark);
	border: none;
	outline: none;
	margin-top: 24px;
	cursor: pointer;
}

/* Mobile L */
@media screen and (max-width: 425px) {
	.form-container {
		width: 320px;
	}
}

/* Mobile M */
@media screen and (max-width: 375px) {
	.form-container {
		width: 280px;
	}
}

/* Mobile S */
@media screen and (max-width: 325px) {
	.form-container {
		width: 90%;
		box-sizing: border-box;
		padding: 16px;
	}
}
