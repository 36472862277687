.root {
	padding: 2px 8px 2px 2px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	/*width: 260px;*/
	font-size: 20px;
	background: #fff;
	color: #fff;
	border-radius: 2px;
	border: none;
	box-shadow: 1px 1px 1px grey;
	font-family: "'Roboto'", sans-serif;
	cursor: pointer;
	background: #4c8bf5;
	font-weight: 600;
}
.root:active {
	box-shadow: none;
}
.root:disabled {
	background: #ccc;
	cursor: default;
	box-shadow: none;
}

.root svg {
	font-size: 26px;
	background: #fff;
	padding: 4px;
	border-radius: 2px;
	margin-right: 8px;
}

/* Mobile L */
@media screen and (max-width: 425px) {
	.root {
		font-size: 19px;
	}
}
